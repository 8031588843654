import "./styles.scss";

import React from "react";

const mainClass = "privacy-policy";

const Content = ({ content }) => {
  return (
    <div className={mainClass}>
      <div className={`${mainClass}__header`}>
        <div className="container">
          <h1>Polityka prywatności</h1>
        </div>
      </div>
      <div className={`${mainClass}__content`}>
        <div className="container container--medium">
          <h5>1. Administrator danych</h5>
          <p className="small">
            Administratorem Państwa danych osobowych jest Haveno Estate Sp. z
            o.o. spółka z ograniczoną odpowiedzialnością z siedzibą w Gdańsku
            A\l. Zwycięstwa 13A, 80-219 Gdańsk, NIP5842779311, adres do
            korespondencji: Al. Zwycięstwa 13A, 80-219 Gdańsk, Polska
          </p>
          <p className="small">
            Kontakt z Administratorem w sprawie ochrony danych osobowych jest
            możliwy pod adresem e-mail{" "}
            <a className="small" href="mailto:biuro@sprzedazapartamenty.com.pl">
              biuro@sprzedazapartamenty.com.pl
            </a>
          </p>
          <p className="small">
            Administrator dokłada szczególnej staranności w celu ochrony
            interesów osób, których dane dotyczą, a w szczególności zapewnia, że
            zbierane przez niego dane są przetwarzane zgodnie z prawem, zbierane
            dla oznaczonych, zgodnych z prawem celów i niepoddawane dalszemu
            przetwarzaniu niezgodnemu z tymi celami, merytorycznie poprawne i
            adekwatne w stosunku do celów, w jakich są przetwarzane, oraz
            przechowywane w postaci umożliwiającej identyfikację osób, których
            dotyczą, nie dłużej niż jest to niezbędne do osiągnięcia celu
            przetwarzania. Administrator przykłada także szczególną wagę do
            polityki informowania Państwa o wszystkim co dotyczy danych
            osobowych, podanych przez Państwa w ramach kontaktów ze Spółką, jak
            również w pełnym zakresie zapewnia bezpieczeństwo danych osobowych,
            w tym gwarantuje wpływ na zakres przetwarzanych danych oraz ułatwia
            wykonywanie wszelkich przysługujących Państwu praw.
          </p>
          <h5>2. Cele i podstawy przetwarzania danych osobowych</h5>
          <p className="small">
            Administrator przetwarza Państwa dane osobowe — w różnych celach,
            jednak zawsze zgodnie z prawem. Poniżej znajdziecie Państwo
            wyszczególnione cele przetwarzania danych osobowych wraz z
            podstawami prawnymi.
          </p>
          <p className="small">
            W celu obsługi zapytania przesłanego przez formularz kontaktowy lub
            bezpośrednio drogą elektroniczną przetwarzamy podane dane osobowe,
            jak: imię, nazwisko, dane kontaktowe. Podstawą prawną takiego
            przetwarzania danych jest art. 6 ust. 1 lit. b RODO. Jeżeli
            zdecydujecie się Państwo podać również inne dane, uznajemy, że
            wyrazili Państwo zgodę na przetwarzanie również tych danych — wtedy
            podstawą prawną takiego przetwarzania jest art. 6 ust. 1 lit. a
            RODO, który pozwala przetwarzać dane osobowe na podstawie
            dobrowolnie udzielonej zgody;
          </p>
          <p className="small">
            W przypadku przyjęcia oferty/złożenia zamówienia – w celu zawarcia i
            realizacji umowy, tj. w oparciu o art. 6 ust. 1 lit. b RODO
            przetwarzamy takie dane osobowe jak: imię i nazwisko, adres
            zamieszkania (jeżeli został podany), numer PESEL lub numer NIP, dane
            dokumentów tożsamości, adres e-mail, numer zlecenia (jeżeli zostały
            podane) oraz dane działalności gospodarczej (jeśli zostały podane)
            takie jak: nazwa działalności, adres siedziby, numer NIP, numer
            REGON.
          </p>
          <p className="small">
            W celu ustalenia, dochodzenia lub obrony przed roszczeniami
            przetwarzamy takie dane osobowe, jak: imię i nazwisko, adres
            zamieszkania (jeżeli został podany), numer PESEL lub numer NIP, dane
            dokumentów tożsamości, adres e-mail, numer zlecenia (jeżeli zostały
            podane) oraz dane działalności gospodarczej (jeśli zostały podane)
            takie jak: nazwa działalności, adres siedziby, numer NIP, numer
            REGON.
          </p>
          <p className="small">
            Podstawą prawną takiego przetwarzania danych jest art. 6 ust. 1 lit.
            f RODO, który pozwala przetwarzać dane osobowe, jeżeli tym sposobem
            Administrator Danych Osobowych realizuje swój prawnie uzasadniony
            interes (w tym przypadku interesem Spółki jest posiadanie danych
            osobowych, które pozwolą ustalić, dochodzić lub bronić się przed
            roszczeniami, w tym klientów i osób trzecich);
          </p>
          <p className="small">
            W celu archiwalnym i dowodowym przetwarzamy takie dane osobowe, jak:
            imię i nazwisko (jeżeli zostało podane), adres e-mail, numer
            zlecenia — na potrzeby zabezpieczenia informacji, które mogą służyć
            wykazywaniu faktów o znaczeniu prawnym. Podstawą prawną takiego
            przetwarzania danych jest art. 6 ust. 1 lit. f RODO, który pozwala
            przetwarzać dane osobowe, jeżeli tym sposobem Administrator Danych
            Osobowych realizuje swój prawnie uzasadniony interes (w tym
            przypadku interesem Spółki jest posiadanie danych osobowych, które
            pozwolą dowieść pewnych faktów związanych z realizacją usług, np.
            gdy jakiś organ państwowy tego zażąda);
          </p>
          <p className="small">
            W celu realizacji obowiązków nałożonych na Administratora przepisami
            prawa (art. 6 ust. 1 lit. c RODO)
          </p>
          <p className="small">
            W celu analitycznym, tj. badania i analizowania aktywności na
            stronie internetowej należącej do Spółki, przetwarzamy takie dane
            osobowe, jak: data i godzina odwiedzin strony, rodzaj systemu
            operacyjnego, przybliżona lokalizacja, rodzaj przeglądarki
            internetowej wykorzystywanej do przeglądania strony, czas spędzony
            na stronie, odwiedzone podstrony, podstrona, gdzie wypełniono
            formularz kontaktowy. Podstawą prawną takiego przetwarzania danych
            jest art. 6 ust. 1 lit. f RODO, który pozwala przetwarzać dane
            osobowe, jeżeli tym sposobem Administrator Danych Osobowych
            realizuje swój prawnie uzasadniony interes (w tym przypadku
            interesem Spółki jest poznanie aktywności klientów na stronie
            internetowej);
          </p>
          <p className="small">
            W celu wykorzystywania cookies na stronie internetowej przetwarzamy
            takie informacje tekstowe (cookies zostaną opisane w odrębnym
            punkcie). Podstawą prawną takiego przetwarzania jest art. 6 ust. 1
            lit. a RODO, który pozwala przetwarzać dane osobowe na podstawie
            dobrowolnie udzielonej zgody (przy pierwszym wejściu na stronę
            internetową pojawia się zapytanie o zgodę na wykorzystanie cookies);
          </p>
          <p className="small">
            W celu administrowania stroną internetową przetwarzamy takie dane
            osobowe, jak: adres IP, data i czas serwera, informacje o
            przeglądarce internetowej, informacje o systemie operacyjnym — dane
            te są zapisywane automatycznie w tzw. logach serwera, przy
            każdorazowym korzystaniu ze strony należącej do Spółki.
            Administrowanie stroną internetową bez użycia serwera i bez tego
            automatycznego zapisu nie byłoby możliwe. Podstawą prawną takiego
            przetwarzania danych jest art. 6 ust. 1 lit. f RODO, który pozwala
            przetwarzać dane osobowe, jeżeli tym sposobem Administrator Danych
            Osobowych realizuje swój prawnie uzasadniony interes (w tym
            przypadku interesem Spółki jest administrowanie stroną internetową);
          </p>
          <h5>3. Cookies</h5>
          <p className="small">
            Spółka, na swojej stronie internetowej, podobnie jak inne podmioty,
            wykorzystuje tzw. cookies, czyli krótkie informacje tekstowe,
            zapisywane na komputerze, telefonie, tablecie, czy też innym
            urządzeniu użytkownika. Mogą być one odczytywane przez nasz system,
            a także przez systemy należące do innych podmiotów, z których usług
            korzystamy (np. Google’a).
          </p>
          <p className="small">
            Cookies spełniają bardzo wiele funkcji na stronie internetowej,
            najczęściej przydatnych, które postaramy się opisać poniżej (jeżeli
            informacje są niewystarczające, prosimy o kontakt):
          </p>
          <ul>
            <li className="small">
              zapewnianie bezpieczeństwa — pliki cookies są wykorzystywane w
              celu uwierzytelniania użytkowników oraz zapobiegania
              nieupoważnionemu korzystaniu z panelu klienta. Służą zatem do
              ochrony danych osobowych użytkownika przed dostępem osób
              nieupoważnionych;
            </li>
            <li className="small">
              wpływ na procesy i wydajność korzystania ze strony internetowej —
              pliki cookies są wykorzystywane do tego, aby witryna sprawnie
              działała i aby można było korzystać z funkcji na niej dostępnych,
              co jest możliwe między innymi dzięki zapamiętywaniu ustawień
              pomiędzy kolejnymi odwiedzinami na stronie. Dzięki nim można zatem
              sprawnie poruszać się na stronie internetowej i poszczególnych
              podstronach;
            </li>
            <li className="small">
              stan sesji — w plikach cookies często są zapisywane informacje o
              tym, jak odwiedzający korzystają ze strony internetowej, np. które
              podstrony najczęściej wyświetlają. Umożliwiają również
              identyfikację błędów wyświetlanych na niektórych podstronach.
              Pliki cookies służące do zapisywania tzw. „stanu sesji” pomagają
              zatem ulepszać usługi i zwiększać komfort przeglądania stron;
            </li>
            <li className="small">
              utrzymanie stanu sesji — jeżeli klient loguje się do swojego
              panelu, to pliki cookies umożliwiają podtrzymanie sesji. Oznacza
              to, że po przejściu na inną podstronę nie trzeba każdorazowo
              podawać ponownie loginu i hasła, co sprzyja komfortowi korzystania
              ze strony internetowej;
            </li>
            <li className="small">
              tworzenie statystyk — pliki cookies są wykorzystywane do tego, aby
              przeanalizować, w jaki sposób użytkownicy korzystają ze strony
              internetowej (jak wielu otwiera stronę internetową, jak długo na
              niej pozostają, które treści wzbudzają największe zainteresowanie
              etc.). Dzięki temu można stale ulepszać stronę internetową i
              dostosowywać jej działanie do preferencji użytkowników. W celu
              śledzenia aktywności i tworzenia statystyk wykorzystujemy
              narzędzia Google’a, takie jak Google Analytics; oprócz
              raportowania statystyk użytkowania witryny pikselowy Google
              Analytics może również służyć, razem z niektórymi opisanymi
              powyżej plikami cookies, do pomocy w wyświetlaniu użytkownikowi
              bardziej trafnych treści w usługach Google (np. w wyszukiwarce
              Google) i w całej sieci;
            </li>
            <li className="small">
              wyświetlanie reklam – ten rodzaj cookie pozwala dostosowywać
              reklamy, dzięki którym korzystanie ze stron internetowych może być
              bezpłatne – do preferencji i zwyczajów ich użytkowników.
              Umożliwiają one dostarczanie użytkownikom treści reklamowych
              bardziej dostosowanych do ich zainteresowań i potrzeb oraz
              pozwalają ocenić skuteczność działań reklamowych.
            </li>
          </ul>
          <p className="small">
            Co ważne, wiele plików cookies ma dla nas charakter zanonimizowany —
            bez dodatkowych informacji, na ich podstawie nie jesteśmy w stanie
            zidentyfikować Państwa tożsamości.
          </p>
          <p className="small">
            Państwa przeglądarka internetowa domyślnie dopuszcza wykorzystywanie
            cookies w Państwa urządzeniu, dlatego przy pierwszej wizycie prosimy
            o wyrażenie zgody na użycie cookies. Jeżeli jednak nie życzą sobie
            Państwo wykorzystania cookies przy przeglądaniu strony internetowej,
            można zmienić ustawienia w przeglądarce internetowej — całkowicie
            blokować automatyczną obsługę plików cookies lub żądać powiadomienia
            o każdorazowym zamieszczeniu cookies w urządzeniu. Ustawienia można
            zmienić w dowolnej chwili.
          </p>
          <p className="small">
            Szanując autonomię wszystkich osób korzystających ze strony
            internetowej, czujemy się jednak w obowiązku uprzedzić, że
            wyłączenie lub ograniczenie obsługi plików cookies może spowodować
            dość poważne trudności w korzystaniu ze strony internetowej, np. w
            postaci konieczności logowania się na każdej podstronie, dłuższego
            okresu ładowania się strony, ograniczeń w korzystaniu z
            funkcjonalności.
          </p>
          <h5>4. Prawo wycofania zgody</h5>
          <p className="small">
            Jeżeli przetwarzanie danych osobowych odbywa się na podstawie zgody,
            w każdej chwili mogą Państwo tę zgodę cofnąć — wedle własnego
            uznania.
          </p>
          <p className="small">
            Jeżeli chcielibyście Państwo cofnąć zgodę na przetwarzanie danych
            osobowych, to w tym celu wystarczy wysłać maila na adres wskazany w
            pkt I Polityki lub listownie na adres siedziby Administratora
            wskazany w pkt I Polityki.
          </p>
          <p className="small">
            Jeżeli przetwarzanie Państwa danych osobowych odbywało się na
            podstawie zgody, jej cofnięcie nie powoduje, że przetwarzanie danych
            osobowych do tego momentu było nielegalne. Innymi słowy, do czasu
            cofnięcia zgody mamy prawo przetwarzać Państwa dane osobowe i jej
            odwołanie nie wpływa na zgodność z prawem dotychczasowego Podanie
            przez Państwa danych osobowych jest zawsze dobrowolne.
          </p>
          <h5>5. Wymóg podania danych osobowych</h5>
          <p className="small">
            Podanie jakichkolwiek danych osobowych jest dobrowolne i zależy od
            Państwa decyzji. Jednakże w niektórych przypadkach podanie
            określonych danych osobowych jest konieczne, aby np. móc
            skontaktować się z Państwem w celu obsługi zapytania ofertowego czy
            zrealizować umowę lub spełnić Państwa oczekiwania w zakresie
            korzystania z platformy.
          </p>
          <h5>6. Zautomatyzowane podejmowanie decyzji i profilowanie</h5>
          <p className="small">
            Uprzejmie informujemy, że nie dokonujemy zautomatyzowanego
            podejmowania decyzji, w tym w oparciu o profilowanie.
          </p>
          <h5>7. Odbiorcy danych osobowych</h5>
          <p className="small">
            Jak większość przedsiębiorców, w swojej działalności korzystamy z
            pomocy innych podmiotów, co niejednokrotnie wiąże się z
            koniecznością przekazania danych osobowych. W związku z powyższym, w
            razie potrzeby, przekazujemy Państwa dane osobowe następującym
            odbiorcom: Haveno Estate Sp. z o.o., podmiotom świadczącym usługi
            informatyczne, podmiotom świadczącym usługi hostingowe, firmom
            transportowym, operatorom pocztowym/kurierom, podmiotom świadczącym
            usługi prawne, bankom, podmiotom współpracującym przy realizacji
            zleceń (w zakresie w jakim jest to niezbędne do realizacji celu
            przetwarzania).
          </p>
          <p className="small">
            Oprócz tego, może się zdarzyć, że np. na podstawie właściwego
            przepisu prawa lub decyzji właściwego organu będziemy musieli
            przekazać Państwa dane osobowe również innym podmiotom, czy to
            publicznym czy prywatnym. Dlatego niezmiernie trudno nam
            przewidzieć, kto może zgłosić się z żądaniem udostępnienia danych
            osobowych. Niemniej ze swojej strony zapewniamy, że każdy przypadek
            żądania udostępnienia danych osobowych analizujemy bardzo starannie
            i bardzo wnikliwie, aby niechcący nie przekazać informacji osobie
            nieuprawnionej.
          </p>
          <h5>8. Przekazywanie danych osobowych do państw trzecich</h5>
          <p className="small">
            Uprzejmie informujemy, iż Państwa dane osobowe mogą być przekazywane
            do państw trzecich, co jest związane z przetwarzaniem danych
            osobowych w systemach informatycznych. W przypadku przekazywania
            danych do państw trzecich, będzie ono odbywało się z wykorzystaniem
            standardowych klauzul umownych zatwierdzonych przez Komisję
            Europejską, w celu zapewnienia odpowiedniego poziomu ochrony danych
            wymaganego przepisami prawa.
          </p>
          <h5>9. Okres przetwarzania danych osobowych</h5>
          <p className="small">
            Zgodnie z obowiązującymi przepisami prawa nie przetwarzamy Państwa
            danych osobowych „w nieskończoność”, lecz przez czas, który jest
            potrzebny, aby osiągnąć wyznaczony cel. Po tym okresie Państwa i
            dane osobowe zostaną nieodwracalnie usunięte lub zniszczone.
          </p>
          <p className="small">
            Staramy się maksymalnie ograniczać okres przechowywania danych
            osobowych, które nie są już wykorzystywane. Nie mniej, jednakże
            Administrator z przyczyn technologicznych czy też operacyjnych
            potrzebuje czasu na usunięcie danych z kopii bezpieczeństwa. Do
            podanych poniżej okresów należy doliczyć 30 dni na usunięcie danych
            z kopii bezpieczeństwa.
          </p>
          <p className="small">
            Odnośnie poszczególnych okresów przetwarzania danych osobowych,
            uprzejmie informujemy, że Państwa dane przetwarzane do celu obsługi
            zapytania będą u nas przechowywane przez:
          </p>
          <ul>
            <li className="small">
              okres trwania korespondencji w sprawie i niezbędny do realizacji
              celu wynikającego z treści zapytania, a następnie, w zależności od
              tego, jakie będą rezultaty korespondencji – albo zasilą bazę
              naszych klientów i będą dalej przetwarzane w celu realizacji umowy
              albo dane zostaną usunięte, jeżeli nie zaistnieje możliwość
              nawiązania współpracy. W przypadku czytelnego dla nas zamknięcia
              rozmów z Państwa strony, dane zostaną usunięte niezwłocznie z
              roboczych baz systemowych. W przypadku zawarcia umowy, dane będą
              przetwarzane przez czas niezbędny do realizacji i korzystania z
              praw i obowiązków z niej wynikających, w tym przez czas niezbędny
              do realizacji obowiązków wynikających z przepisów prawa nałożonych
              na Administratora i dochodzenia roszczeń oraz obrony przed
              roszczeniami,
            </li>
            <li className="small">
              do czasu cofnięcia zgody lub osiągnięcia celu przetwarzania,
              jednak nie dłużej niż przez 10 lat — w odniesieniu do danych
              osobowych przetwarzanych na podstawie zgody;
            </li>
            <li className="small">
              do czasu skutecznego wniesienia sprzeciwu lub osiągnięcia celu
              przetwarzania, jednak nie dłużej niż przez 10 lat — w odniesieniu
              do danych osobowych przetwarzanych na podstawie prawnie
              uzasadnionego interesu Administratora Danych Osobowych,
            </li>
            <li className="small">
              do czasu zdezaktualizowania się lub utraty przydatności, jednak
              nie dłużej niż przez 10 lat — w odniesieniu do danych osobowych
              przetwarzanych głównie do celów analitycznych, wykorzystania
              cookies i administrowania stroną internetową.
            </li>
          </ul>
          <h5>10. Uprawnienia podmiotów danych</h5>
          <p className="small">
            Uprzejmie informujemy, że posiadają Państwo prawo do:
          </p>
          <ul>
            <li className="small">dostępu do swoich danych osobowych;</li>
            <li className="small">sprostowania danych osobowych;</li>
            <li className="small">usunięcia danych osobowych;</li>
            <li className="small">
              ograniczenia przetwarzania danych osobowych;
            </li>
            <li className="small">
              sprzeciwu wobec przetwarzania danych osobowych;
            </li>
            <li className="small">przenoszenia danych osobowych.</li>
          </ul>
          <p className="small">
            Wskazujemy, że wymienione uprawnienia nie mają charakteru
            absolutnego, a zatem w niektórych sytuacjach możemy zgodnie z prawem
            odmówić Państwu ich spełnienia. Jednakże, jeżeli odmawiamy
            uwzględnienia żądania, to tylko po wnikliwej analizie i tylko w
            sytuacji, gdy odmowa uwzględnienia żądania jest konieczna.
          </p>
          <ul>
            <li className="small">
              istnieją prawnie uzasadnione podstawy do przetwarzania, które są
              nadrzędne w stosunku do Państwa interesów, praw i wolności lub
              istnieją podstawy do ustalenia, dochodzenia lub obrony roszczeń.
            </li>
          </ul>
          <p className="small">
            Swoje uprawnienia mogą Państwo zrealizować poprzez wysłanie e-mail
            pod adres{" "}
            <a className="small" href="mailto:biuro@sprzedazapartamenty.com.pl">
              biuro@sprzedazapartamenty.com.pl
            </a>
            .
          </p>
          <h5>11. Prawo do wniesienia skargi</h5>
          <p className="small">
            Jeżeli uważają Państwo, że Państwa dane osobowe są przetwarzane
            niezgodnie z obowiązującym prawem, mogą Państwo wnieść skargę do
            Prezesa Urzędu Ochrony Danych Osobowych.
          </p>
          <h5>12. Postanowienia końcowe</h5>
          <p className="small">
            W zakresie nieuregulowanym niniejszą Polityką prywatności obowiązują
            przepisy z zakresu ochrony danych osobowych.
          </p>
          <p className="small">
            Niniejsza Polityka prywatności obowiązuje od dnia 23 grudnia 2020 r.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Content;
