import React from "react";

import Layout from "components/Layout";
import Seo from "components/Layout/components/Seo";
import Content from "page_components/privacy-policy/Content";

const PrivacyPolicy = () => {
  return (
    <Layout hide_contact_section>
      <Content />
    </Layout>
  );
};

export const Head = () => <Seo title="Polityka prywatności | Brzegova" />;

export default PrivacyPolicy;
